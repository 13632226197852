<template>
    <div class="zm-drag-drop">
        <div :class="{'zm-drag-virtual': true, 'is-process': STATE.isProcess, 'is-ready': STATE.isReady}" v-html="STATE.outerHTML"
            :style="{top: STATE.originalEvent.clientY + 3 + 'px', left: STATE.originalEvent.clientX + 3 + 'px', width: STATE.clientRect.width + 'px', height: STATE.clientRect.height + 'px'}">
        </div>
    </div>
</template>
<script>
import {
    FOLDER_VIEW
} from "@/utils/constants";
export default {
    name: "BaseDragDrop",
    props: {
    },
    data() {
        return {
            DRAG_SELECTOR: '.zm-dragable',
            DROP_SELECTOR: '.zm-dropable',
            STATE: {
                outerHTML: null,
                isDowning: false,
                isProcess: false, // no process
                isReady: false,
                allowExpand: false,
                clientRect: {top: 0, left: 0, width: 200, height: 60},
                originalEvent: {clientX: 0, clientY: 0},
            },
            sourceTarget: null,
        }
    },
    mounted() {
        const $ = window.jQuery;
        $('#appZimbra > .drag-wrapper').on('mousedown', this.DRAG_SELECTOR, this.markItDowning);
        $('#appZimbra > .drag-wrapper').on('mouseup', this.DRAG_SELECTOR, this.unmarkItDowning);
        $('#appZimbra > .drag-wrapper').on('mousemove', this.DRAG_SELECTOR, this.onMousedownDragSelector);
        $('#appZimbra').on('mouseup', '> .drag-wrapper.drag-start', this.onMouseupAppZimbra);
        $('#appZimbra').on('mousemove', '> .drag-wrapper.drag-start', this.onMousemoveAppZimbra);
        $('#appZimbra').on('mousemove', '> .drag-wrapper.drag-start ' + this.DROP_SELECTOR, this.onMousemoveDropSelector);
        $('#appZimbra').on('mouseout', '.wait-to-drop' + this.DROP_SELECTOR, this.resetDropSelector);
        // $('#appZimbra').on('mousemove', '> .drag-wrapper.drag-start .wait-to-drop' + this.DROP_SELECTOR, this.markBeginWaitToDrop);
        $('#appZimbra').on('mouseup', '> .drag-wrapper.drag-start .wait-to-drop' + this.DROP_SELECTOR, this.onDropFinish);
    },
    computed: {
    },
    created() {
    },
    watch: {
    },
    methods: {
        _setIsProcess(flag) {
            const $ = window.jQuery;
            this.STATE.isProcess = flag;
            if (flag) {
                $('#appZimbra > .drag-wrapper').addClass('drag-start');
            } else {
                $('#appZimbra > .drag-wrapper').removeClass('drag-start');
                this.STATE.isDowning = false;
                this.sourceTarget = null;
            }

        },
        markItDowning(event) {
            if(event.which == 1)
            this.STATE.isDowning = true;
        },
        unmarkItDowning(event) {
            if(event.which == 1)
            this.STATE.isDowning = false;
        },
        onMousedownDragSelector(evt) {
            if (this.STATE.isProcess || !this.STATE.isDowning) {
                return;
            }
            this._setIsProcess(true);
            this.STATE.outerHTML = evt.currentTarget.outerHTML;
            this.STATE.clientRect = evt.currentTarget.getBoundingClientRect();
            this.STATE.originalEvent = evt.originalEvent;
            this.sourceTarget = evt.currentTarget;
        },
        onMousemoveDropSelector(evt) {
            if (!this.STATE.isProcess) {
                return;
            }
            const $ = window.jQuery;
            const $currentTarget = $(evt.currentTarget);
            $currentTarget.addClass('wait-to-drop');
            this.STATE.isReady = true;
            this.STATE.allowExpand = true;
            const type = this.sourceTarget.getAttribute("type");
            if(type == FOLDER_VIEW.APPOINTMENT) {
                return;
            }
            if (type) { // th Drop folder
                if (type == FOLDER_VIEW.DOCUMENT) {
                    this.$root.$emit("onMousemoveFolderBriefcaseDropSelector", this.STATE, this.sourceTarget, evt.currentTarget);
                } else if (type == FOLDER_VIEW.CONTACT) {
                    this.$root.$emit("onMousemoveFolderDropContact", this.STATE, this.sourceTarget, evt.currentTarget);
                } else {
                    this.$root.$emit("onMousemoveFolderDropSelector", this.STATE, this.sourceTarget, evt.currentTarget);
                }
            } else { // th Drop mail
                this.$root.$emit("onMousemoveMailDropSelector", this.STATE, evt.currentTarget);
                this.$root.$emit("onMousemoveContactDropSelector", this.STATE,this.sourceTarget, evt.currentTarget);
            }
            const idFolderExpand = evt.currentTarget.getAttribute("id");
            setTimeout(() => {
                if (this.STATE.allowExpand && idFolderExpand) {
                    this.$root.$emit("extendFolderById", idFolderExpand);
                }
            }, 2000)
        },
        resetDropSelector(evt) {
            const $ = window.jQuery;
            const $currentTarget = $(evt.currentTarget);
            $currentTarget.removeClass('wait-to-drop');
            this.STATE.allowExpand = false;
            this.STATE.isReady = false;
        },
        onMousemoveAppZimbra(evt) {
            this.STATE.originalEvent = evt.originalEvent;
        },
        onMouseupAppZimbra() {
            this._setIsProcess(false);
        },
        onDropFinish(evt) {
            this.$root.$emit("onDropFinish", this.sourceTarget, evt.currentTarget)
        },
    }
}
</script>
<style scoped>
.zm-drag-virtual {
    position: fixed;
    border: 2px solid red;
    background-color: white;
    z-index: 8888;
    overflow: hidden;
    display: none;
    opacity: 0.8;
    pointer-events: none;
}
.is-process {
    display: block;
}

.zm-drag-virtual.is-ready {
    border-color: green;
}
</style>
