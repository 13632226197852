<template>
    <el-dialog
        class="dialog-body-wrap d-center "
        id="dialog-shortkey"
        custom-class="modal-show-short-key dark-mode"
        :title="this.$t('zimbra.zmMsg.keyboardShortcuts')"
        :visible.sync="dialogShortKey"
        :close-on-click-modal="false"
        :append-to-body="true"
        :destroy-on-close="true"
    >
        <el-form class="form-wrap">
            <div class="form__heading">
                <span class="title">{{ $t("zimbra.zmMsg.shortcutsCurrent") }}</span>
                <base-button-default class="btn-email primary" @click="handleOpenWindow">
                    {{ $t("zimbra.zmMsg.newWindow") }}
                </base-button-default>
            </div>
            <div class="ps">
                <div class="form__body">
                    <div class="shortcut-div" id="shortcut-area">
                        <dl v-for="(key_short, index) in keyboard_shortcuts" :key="index" class="text item one-item">
                            <lh class="title">{{ key_short.title }}</lh>
                            <div class="row-data" v-for="(data, index) in key_short.content" :key="index">
                                <dd class="content-title">{{ data.title }}</dd>
                                <dt v-for="(shortcut, index) in data.listShortcuts" :key="index" class="key">
                                    <span class="shortcut-key">{{ shortcut.key }}</span>
                                </dt>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
import BaseButtonDefault from "@/components/modules/base/base-button/btn-default";
import $ from 'jquery';
export default {
    mounted() {
        this.$root.$on("handleShowShortKey", (data) => {
            if(!this.dialogShortKey){
                this.dialogShortKey = data;
            }
        });
    },
    components: {
        BaseButtonDefault,
    },
    destroyed() {
        this.$root.$off("handleShowShortKey")
    },
    data() {
        return {
            dialogShortKey: false,
            keyboard_shortcuts: [
                {
                    title: this.$t(
                        "zimbra.zhMsg.ALT_APP_OPTIONS"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbra.zhMsg.actionSave"
                            ),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "S",
                                },
                            ],
                        },
                    ],
                },
                 {
                    title: this.$t("zimbra.zmMsg.allApplications"),
                    content: [
                        {
                            title: this.$t("zimbra.zmMsg.goToMail"),
                            listShortcuts: [
                                {
                                    key: "G",
                                },
                                {
                                    key: "M",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zmMsg.goToContacts"),
                            listShortcuts: [
                                {
                                    key: "G",
                                },
                                {
                                    key: "A",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zmMsg.goToCalendar"),
                            listShortcuts: [
                                {
                                    key: "G",
                                },
                                {
                                    key: "C",
                                },
                            ],
                        },
                        // {
                        //     title: this.$t(
                        //         "zimbra.setting.keyboard_shortcuts.all_applications.content.go_to_briefcase"
                        //     ),
                        //     listShortcuts: [
                        //         {
                        //             key: "G",
                        //         },
                        //         {
                        //             key: "B",
                        //         },
                        //     ],
                        // },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_go_to_preferences"),
                            listShortcuts: [
                                {
                                    key: "G",
                                },
                                {
                                    key: "P",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_focus_search_box"),
                            listShortcuts: [
                                {
                                    key: "/",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_focus_content_pane"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "/",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_focus_toolbar"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Y",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_new_item"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.compose" ),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "M",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.compose"),
                            listShortcuts: [
                                {
                                    key: "C",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_compose_in_new_window"),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "C",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.newContact"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "C",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.ALT_CAL_NEW_APPT"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "A",
                                },
                            ],
                        },
                        // {
                        //     title: this.$t(
                        //         "zimbra.setting.keyboard_shortcuts.all_applications.content.new_task"
                        //     ),
                        //     listShortcuts: [
                        //         {
                        //             key: "N",
                        //         },
                        //         {
                        //             key: "K",
                        //         },
                        //     ],
                        // },
                        {
                            title: this.$t("zimbra.zhMsg.calendarNew"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "L",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_new_buddy"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "B",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_add_external_calendar"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "E",
                                },
                            ],
                        },
                        // {
                        //     title: this.$t(
                        //         "zimbra.setting.keyboard_shortcuts.all_applications.content.new_document"
                        //     ),
                        //     listShortcuts: [
                        //         {
                        //             key: "N",
                        //         },
                        //         {
                        //             key: "D",
                        //         },
                        //     ],
                        // },
                        {
                            title: this.$t("zimbra.zhMsg.tagNew"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "T",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zmMsg.nextPage"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "→",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zmMsg.previousPage"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "←",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.actionPrint"),
                            listShortcuts: [
                                {
                                    key: "P",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_open_a_search_tab"),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                                {
                                    key: "S",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_delete_item"),
                            listShortcuts: [
                                {
                                    key: "Del",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_delete_item"),
                            listShortcuts: [
                                {
                                    key: "Backspace",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_hard_delete_item"),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "Del",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_hard_delete_item"),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "Backspace",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.close"),
                            listShortcuts: [
                                {
                                    key: "Esc",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_quick_reminder"),
                            listShortcuts: [
                                {
                                    key: "!",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_go_to_visit_folder"),
                            listShortcuts: [
                                {
                                    key: "V",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_go_to_visit_tag"),
                            listShortcuts: [
                                {
                                    key: "V",
                                },
                                {
                                    key: "V",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_move_item"),
                            listShortcuts: [
                                {
                                    key: "M",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_move_item"),
                            listShortcuts: [
                                {
                                    key: "M",
                                },
                                {
                                    key: "M",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_tag_item"),
                            listShortcuts: [
                                {
                                    key: "T",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbra.zhMsg.actionRemoveTag"),
                            listShortcuts: [
                                {
                                    key: "U",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_run_a_saved_search"),
                            listShortcuts: [
                                {
                                    key: "S",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_select_all_search_result"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "A",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_show_shortcuts"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Q",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_show_shortcuts"),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "/",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_show_right_click_menu"),
                            listShortcuts: [
                                {
                                    key: ",",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_show_right_click_menu"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_show_right_click_menu"),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Space",
                                },
                            ],
                        },
                        {
                            title: this.$t("zimbraNokey.zimbra_setting_keyboard_shortcuts_all_applications_content_show_right_click_menu"),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "F10",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: this.$t(
                        "zimbra.zhMsg.ALT_APP_BRIEFCASE"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_briefcase_content_reading_page_at_bottom"
                            ),
                            listShortcuts: [
                                {
                                    key: "M",
                                },
                                {
                                    key: "P",
                                },
                                {
                                    key: "B",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_briefcase_content_reading_page_on_right"
                            ),
                            listShortcuts: [
                                {
                                    key: "M",
                                },
                                {
                                    key: "P",
                                },
                                {
                                    key: "R",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_briefcase_content_turn_off_reading_page"
                            ),
                            listShortcuts: [
                                {
                                    key: "M",
                                },
                                {
                                    key: "P",
                                },
                                {
                                    key: "0",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: this.$t(
                        "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_title"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_press_button"
                            ),
                            listShortcuts: [
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_press_button"
                            ),
                            listShortcuts: [
                                {
                                    key: "Space",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: ",",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Space",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "F10",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_buttons_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "↓",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: this.$t(
                        "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_title"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_content_next_item"
                            ),
                            listShortcuts: [
                                {
                                    key: "↓",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_content_previous_item"
                            ),
                            listShortcuts: [
                                {
                                    key: "↑",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_content_scroll_up"
                            ),
                            listShortcuts: [
                                {
                                    key: "Page Up",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_content_scroll_down"
                            ),
                            listShortcuts: [
                                {
                                    key: "Page Down",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbra.zhMsg.select"
                            ),
                            listShortcuts: [
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbra.zhMsg.select"
                            ),
                            listShortcuts: [
                                {
                                    key: "Space",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbra.zmMsg.dismiss"
                            ),
                            listShortcuts: [
                                {
                                    key: "Esc",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_content_show_submenu"
                            ),
                            listShortcuts: [
                                {
                                    key: "→",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_popup_menu_content_hide_submenu"
                            ),
                            listShortcuts: [
                                {
                                    key: "←",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: this.$t(
                        "zimbra.zhMsg.SKIN_tree"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_next_item"
                            ),
                            listShortcuts: [
                                {
                                    key: "↓",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_previous_item"
                            ),
                            listShortcuts: [
                                {
                                    key: "↑",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbra.zmMsg.expand"
                            ),
                            listShortcuts: [
                                {
                                    key: "→",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbra.zmMsg.collapse"
                            ),
                            listShortcuts: [
                                {
                                    key: "←",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_select_first_item"
                            ),
                            listShortcuts: [
                                {
                                    key: "Home",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_select_in_drop_down"
                            ),
                            listShortcuts: [
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_select_last_item"
                            ),
                            listShortcuts: [
                                {
                                    key: "End",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: ",",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "Ctrl",
                                },
                                {
                                    key: "Space",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_trees_content_display_menu"
                            ),
                            listShortcuts: [
                                {
                                    key: "Shift",
                                },
                                {
                                    key: "F10",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: this.$t(
                        "zimbraNokey.zimbra_setting_keyboard_shortcuts_dialog_boxes_title"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbra.zhMsg.saveChanges"
                            ),
                            listShortcuts: [
                                {
                                    key: "Enter",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbra.zhMsg.saveChanges"
                            ),
                            listShortcuts: [
                                {
                                    key: "Space",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_dialog_boxes_content_cancel_change"
                            ),
                            listShortcuts: [
                                {
                                    key: "Esc",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_dialog_boxes_content_respond_yes"
                            ),
                            listShortcuts: [
                                {
                                    key: "Y",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_dialog_boxes_content_respond_no"
                            ),
                            listShortcuts: [
                                {
                                    key: "N",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: this.$t(
                        "zimbraNokey.zimbra_setting_keyboard_shortcuts_toolbars_title"
                    ),
                    content: [
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_toolbars_content_next_button"
                            ),
                            listShortcuts: [
                                {
                                    key: "→",
                                },
                            ],
                        },
                        {
                            title: this.$t(
                                "zimbraNokey.zimbra_setting_keyboard_shortcuts_toolbars_content_previous_button"
                            ),
                            listShortcuts: [
                                {
                                    key: "←",
                                },
                            ],
                        },
                    ],
                },
            ]
        };
    },
    methods: {
        popupWindow(url, title, w, h) {
                var left = ($(window).width() / 2) - (w / 2);
                var top = ($(window).height() / 2) - (h / 2);
                return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
            },
        handleOpenWindow(){
            let documentStyles = 'body{background-color: #000;font-family:"Roboto",sans-serif;margin:0}.modal-show-short-key{margin-top:0 !important;color:#f7f7f7;}.el-dialog__header{text-align:center}button{display:none}.el-dialog__title{font-size: 20px;font-weight:500;line-height:24px}.form__heading{border-bottom: 1px solid #f7f7f7;}.shortcut-div{columns:auto 3;}.one-item .title{font-weight:bold}.title{margin-bottom:1rem;font-style:normal;font-weight:500;font-size:15px;line-height:18px;color:#f7f7f7;}.content-title{font-style:normal;word-break:normal;width:150px;font-size:14px;font-weight:400;color:#f7f7f7;}.row-data{display:flex;margin:30px 0;height:20px;align-items:center;}.shortcut-key{color:yellow;}.key{margin-left:10px;}';
            var printContents = document.getElementById('dialog-shortkey').innerHTML;
                const newWindow = this.popupWindow('', '', 1000, 700);
                newWindow.document.write(`<html><head><style>`);
                newWindow.document.write(documentStyles.toString());
                newWindow.document.write(`</style></head><body>`);
                newWindow.document.body.innerHTML = printContents;
                newWindow.document.write('</body></html>');
                newWindow.document.close();
                newWindow.focus();
                this.dialogShortKey = false;
        }
    },
};
</script>
<style lang="scss">

</style>
