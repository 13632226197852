<template>
    <div id="appZimbra" class="zimbra-wrap h-100" :key="uuid">
        <!-- oncontextmenu="self.event.returnValue=false" -->
        <keep-alive>
            <component :is="layoutSync">
                <router-view :layout.sync="layoutSync" />
            </component>
        </keep-alive>
        <template v-if="showCalendarForm">
            <calendar-form ref="refCalendarForm" />
        </template>
        <base-drag-drop ref="refBaseDragDrop" />
        <dialog-reply-mail
            ref="refDialogReplyMail"
        />
        <feedback/>
        <dialog-short-key ref="refDialogShortKey" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import hotkeys from "hotkeys-js";
import CommonUtils from "@/utils/common-utils";
import $ from "jquery";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import {
    CALENDAR_FREE_BUSY_TYPE,
    CALENDAR_FREQ_TYPE,
    FOLDER_DEFAULT, KEY_LOCAL_STORE,
    KEY_WORD,
    READ_EMAIL_LAYOUT_ROUTER
} from "@/utils/constants";
import CalendarUtils from "@/utils/calendar-utils.js";
import CalendarFrom from "@/components/modules/calendar/calendar-form/calendar-form.vue";
import BaseDragDrop from "@/components/modules/base/base-drag-drop/base-drag-drop.vue";
import DialogReplyMail from "@/components/modules/layouts/components/DialogReplyMail.vue";
import DialogShortKey from "@/components/modules/layouts/components/setting/shortkey/DialogShortKey.vue";
import ZimbraMailService from "@/services/ZimbraMailService";
import CONFIG from "@/config/config";
import Feedback from "@/components/modules/user-guide/Feedback";

export default {
    name: "App",
    mixins: [CommonUtils, CalendarUtils],
    data() {
        return {
            layout: "div",
            uuid: this.generateUniqueId(),
            checkHotkeyBefore: false,
            showCalendarForm: false,
            polling: null
        };
    },
    components: {
        Feedback,
        "calendar-form": CalendarFrom,
        "base-drag-drop": BaseDragDrop,
        "dialog-reply-mail": DialogReplyMail,
        "dialog-short-key": DialogShortKey,
        "feedback": Feedback,
    },
    async created() {
        // TO DO bắt thiết bị là moblie -> hiển thị giao diện kiểu tắt
        if (this.mobileAndTabletCheck() === "mobile" && !this.hasRedirected) {
            const formData = {
                xml: `<urn1:pref name="zimbraPrefReadingPaneLocation">off</urn1:pref>`
            };
            ZimbraMailService.modifyPrefsRequest(formData).then(() => {
                const newPreference = { ...this.preference, zimbraPrefReadMailLayout: "off" };
                this.$store.commit("SET_PREFERENCE", _.cloneDeep(newPreference));
                if (this.$router.currentRoute.path !== READ_EMAIL_LAYOUT_ROUTER.HIDE) {
                    this.$router.push(READ_EMAIL_LAYOUT_ROUTER.HIDE);
                }
            });
            await this.initApp();
            return;
        }
        // !mobile -> vẫn giữ logic cũ
        await this.initApp();
    },
    async mounted() {
        $(".el-dialog__headerbtn").hover(function() {
            $(this).css("cursor", "pointer").attr("title", "Đóng");
        });

        // Cập nhật giao diện diện thời
        this.$root.$on("handleUpdateView", this.handleUpdateView);
        hotkeys("g+p", this.handleGotoSetting);
        hotkeys("g+m", this.handleGoToMail);
        hotkeys("g+a", this.handleGoToContact);
        hotkeys("g+c", this.handleGoToCalendar);
        hotkeys("g+t", this.handleGoToTask);
        hotkeys("g+b", this.handleGoToBriefcase);
        hotkeys("g+p", this.handleGoToPreferences);
        hotkeys("n+c", this.handleNewContact);
        hotkeys("esc", this.handleESC);
        hotkeys("shift+1", this.handleQuickReminder);
        hotkeys("n+a", this.handleNewAppoinment);
        hotkeys("n+k", this.handleNewTask);
        hotkeys("n+l", this.handleNewCalendar);
        hotkeys("n+e", this.handleExternalCalendar);
        hotkeys("shift+c", this.handleComposeNewWindow);
        hotkeys("n+m, n, c", this.handleNewMsg);
        hotkeys("ctrl+q", this.handleShowShortKey);
        hotkeys("shift+/", this.handleShowShortKey);
        this.blockEventKeys("n");
        await this.getThemesSetting();
        // setTimeout(() => {
        const folderInbox = this.allTreeFolder.find(e => e.id === FOLDER_DEFAULT.INBOX);
        if (folderInbox) {
            const title = `VMail: ${folderInbox.name} ${folderInbox.u > 0 ? `(${folderInbox.u})` : ""}`;
            this.setFavicon(folderInbox.u, title);
        }
        // }, 300);

        this.polling = setInterval(() => {
            this.processScanAppointment();
            return () => {
                clearInterval(this.polling);
            };
        }, 10000);

        // Tạo cuộc hẹn
        this.$root.$on("createNewAppointmentRoot", this.createNewAppoitment);

        // Gửi thư
        this.$root.$on("createNewEmailRoot", this.createNewEmail);

        this.$root.$on("searchListEventToWarning", this.searchListEventToWarningApp);


    },
    computed: {
        ...mapGetters([
            "fontCurrent",
            // "fontSizeCurrent",
            "skinCurrent",
            "localeCurrent",
            "allTreeFolder",
            "listAppoinment",
            "listAppoinmentWarning",
            "listCalendarId",
            "menuCollapse",
            "preference"
        ]),
        layoutSync() {
            return this.$route.meta.layout || this.layout;
        }
    },
    methods: {
        ...mapActions([
            "getAllGeneral",
            "initialDataUserLogin",
            "getInfoRequest",
            "initDataSuggestionEmail",
            // "initDataContactLogin",
            "getFilterRulesIncoming",
            "getPrefInbox",
        ]),
        async initApp() {
            await this.getPrefInbox();
            // Load thông tin user đăng nhập
            await this.initialDataUserLogin();
            await this.initDataSuggestionEmail();
            // load contact
            // await this.initDataContactLogin();
            await this.getFilterRulesIncoming(false, false)

            // Load dữ liệu calendar
            await this.innitListCalendarId();
            // await this.initAcountWebexZimlet();
            // await this.initDataPreferencesWebexZimlet();

            // await this.getThemesSetting();
            // this.$mousetrap.reset();
            window.onbeforeunload = this.removeCallBack();
            var body = document.body;
            const { pathname } = window.location;
            if (this.mobileAndTabletCheck() === "tablet") {
                body.classList.add("tablet");
                this.$store.commit("SET_INFO_TABLET", true);
                if (!pathname.includes("/mail/briefcase")) {
                    this.$store.commit("SET_MENU_COLLAPSE", true);
                }
            } else {
                this.$store.commit("SET_INFO_TABLET", false);
                // this.$store.commit('SET_MENU_COLLAPSE', false);
                body.classList.remove("tablet");
                // body.className -= "tablet";
            }

            const fireFoxVersion = this.getFirefoxVersion();
            const isFirefox = this.getFirefoxVersion() != 999 ? true : false;
            if (isFirefox) {
                body.classList.add("is_firefox");
            }
            const isFirefoxSpecialVersion = fireFoxVersion <= 53 ? true : false;
            if (isFirefoxSpecialVersion) {
                body.classList.add("firefox_below");
            }
            // xử lý di chuyển các control trong vùng vertical-control và horizontal-control
            const $ = window.jQuery;
            $("body").on("keydown", ".vertical-control.active-control .focusable", this.verticalControlKey);
            $("body").on("keydown", ".horizontal-control .focusable", this.horizontalControlKey);


            let tokenStorage = this.getLocalStorageObject("zimbra_token");
            let emailAddress = this.$store.state.EmailModule?.emailAddress;
            tokenStorage.username = emailAddress;
            this.addLocalStorageObject("zimbra_token", tokenStorage);

        },
        blockEventKeys(key) {
            for (let index in KEY_WORD) {
                hotkeys(`${key}+${KEY_WORD[index]}`, this.handlePressNextHotKey);
            }
        },
        handleNewMsg(e) {
            if (e.preventDefault) {
                setTimeout(() => {
                    if (!this.checkHotkeyBefore) {
                        const path = [READ_EMAIL_LAYOUT_ROUTER.RIGHT, READ_EMAIL_LAYOUT_ROUTER.HIDE];
                        if (this.$route.path.include(path)) {
                            window.location.href = path;
                            this.addLocalStorageObject("composeContact", { newCompose: true });
                        }
                    }
                }, 1000);
            } else {
                e.returnValue = false;
            }
        },
        handlePressNextHotKey() {
            this.checkHotkeyBefore = true;
            setTimeout(() => {
                this.checkHotkeyBefore = false;
            }, 1000);
        },
        handleComposeNewWindow() {
            const uuid = this.generateUniqueId();
            // Lưu dữ liệu hiện tại vào local storage
            const datacompose = this.getLocalStorageObject("data_compose") || [];
            this.addLocalStorageObject("data_compose", datacompose);
            const { origin } = window.location;
            const height = screen.height * 0.6;
            const width = screen.width * 0.8;
            const left = screen.width * 0.1;
            const top = screen.height * 0.1;
            const style = `width=${width},height=${height},left=${left},top=${top}`;
            window.open(`${origin}/launchNewWindow?compose=1&uuid=${uuid}`, "", style.toString());
        },
        removeCallBack() {
            this.removeLocalStorage("call_back");
        },
        async getThemesSetting() {
            await this.getInfoRequest();
        },
        handleGoToMail() {
            const { name } = this.$route;
            if (name != READ_EMAIL_LAYOUT_ROUTER.NAME_RIGHT && name != READ_EMAIL_LAYOUT_ROUTER.NAME_HIDE) {
                window.location.href = "/mail";
            }
        },
        handleGoToContact() {
            const path = "/mail/contacts";
            if (this.$route.path !== path) {
                window.location.href = path;
            }
        },
        handleGoToCalendar() {
            const path = "/mail/calendar";
            if (this.$route.path !== path) {
                window.location.href = path;
            }
        },
        handleGoToTask() {
            alert("Go to Task");
        },
        handleGoToBriefcase() {
            const path = "/mail/briefcase";
            if (this.$route.path !== path) {
                window.location.href = path;
            }
        },
        handleGoToPreferences() {
            // const path = "/zimbra/setting";
            // if (this.$route.path !== path) {
            //     this.$router.push(path);
            // }
            this.$router.push({ name: "Setting" }).catch(() => {
            });
        },
        handleNewContact() {
            alert("New contact");
        },
        handleESC() {
            if (this.$route?.path.includes("calendar")) {
                this.$router.push({ name: "Calendar" }).catch(() => {
                });
            } else {
                const keySection = KEY_LOCAL_STORE.SETTING_PREF;
                let valueSection = CommonUtils.methods.getLocalStorageObject(keySection);
                const { pref } = valueSection
                const prefs = Array.isArray(pref) ? pref : [pref];
                const zimbraPrefReadingPaneLocation = prefs.find(el => el.name == "zimbraPrefReadingPaneLocation")?.content;
                const offMode = zimbraPrefReadingPaneLocation.includes("off")? true : false
                this.$router.push({ name: offMode? "EmailWithReadPanel" : "Email" }).catch(() => {
                });
            }
        },
        handleQuickReminder() {
            alert("Quick Reminder");
        },
        handleNewAppoinment() {
            alert("New Appointment");
        },
        handleNewTask() {
            alert("New Task");
        },
        handleNewCalendar() {
            alert(" New Calendar");
        },
        handleExternalCalendar() {
            alert("Add external calendar");
        },
        handleShowShortKey() {
            this.$root.$emit("handleShowShortKey", true);
        },
        /**
         * hotkeys chuyển tới tùy chọn
         */
        handleGotoSetting() {
            this.$router.push({ name: "Setting" }).catch(() => {
            });
        },
        /**
         * Hàm innit danh sách danh mục lịch (phục vụ cho tìm kiếm cảnh báo nhắc lịch)
         */
        async innitListCalendarId() {
            // Lấy cấu hình lịch - thùng rác
            const _calFolderRes = await this.getCalFolder();
            let _listCalendarId = [];
            // Lọc danh sách id lịch từ cây lịch
            _calFolderRes.forEach(calParent => {
                this.searchTreeIds(calParent, _listCalendarId);
            });
            // Không tính id thùng rác
            _listCalendarId = _listCalendarId.filter(x => x != FOLDER_DEFAULT.TRASH);
            // Thiết định giá trị lịch vào state
            this.$store.commit("SET_LIST_CALENDAR_ID", _listCalendarId);
        },

        processScanAppointment() {
            const currentDateTime = new Date().getTime();
            if (this.listAppoinment.length === 0) {
                return;
            }
            const listWarning = [];
            this.listAppoinment.forEach(app => {
                if (app.alarm && app.alarmData) {
                    const alarmData = app.alarmData;
                    const diftNowAndStart = alarmData.alarmInstStart - currentDateTime;
                    if (alarmData.nextAlarm <= currentDateTime && (diftNowAndStart > 0 || (diftNowAndStart < 0 && diftNowAndStart * -1 / 1000 / 60 <= 60))) {
                        listWarning.push(app);
                    }
                }
            });
            this.$store.commit("SET_LIST_APPOINMENT_WARNING", listWarning);
        },
        /**
         * Tạo cuộc hẹn
         */
        createNewAppoitment(address) {
            this.showCalendarForm = true;
            const moreDetails = {
                fb: CALENDAR_FREE_BUSY_TYPE.BUSY,
                l: FOLDER_DEFAULT.CALENDAR,
                allDay: false,
                freq: CALENDAR_FREQ_TYPE.NONE,
                alarm: 5,
                class: this.classAppoitment,
                time: [this.getDefautTime(true), this.getDefautTime(false)],
                contentType: ZmMimeTable.TEXT_HTML,
                listAttachment: [],
                customRepeat: null, // tùy chỉnh lặp
                to: Array.isArray(address) ? address : [address]
            };
            this.$nextTick(() => {
                this.$refs.refCalendarForm && this.$refs.refCalendarForm.show(null, null, moreDetails);
            });
        },
        createNewEmail(address, editorModel) {
            const dataCompose = {
                modelData: {
                    to: address
                }
            };
            if (editorModel) {
                dataCompose.modelData.editorModel = editorModel;
            }
            this.$refs.refDialogReplyMail && this.$refs.refDialogReplyMail.showCreateNewMsg(dataCompose);
        },
        handleUpdateView() {
            this.uuid = this.generateUniqueId();
        },
        searchListEventToWarningApp() {
            this.searchListEventToWarning(this.listCalendarId);
        }
        // xử lý di chuyển arrow key trong vertical control
        , verticalControlKey(evt) {
            if (evt.keyCode != 37 // left
                && evt.keyCode != 39 // right
                // && evt.keyCode != 40 // down
            ) {// only accept left, right, down
                return;
            }
            // add lfc class for focus
            const currentTarget = evt.currentTarget;
            // if (evt.keyCode == 40) {
            //     const $currentTarget = $(currentTarget);
            //     if ($currentTarget.hasClass('down-enter')) {
            //         $currentTarget.trigger('click')
            //         return;
            //     }
            // }
            const verticalControl = $(currentTarget).closest(".vertical-control");
            const focusableList = verticalControl.find(".focusable:visible");
            if (focusableList.length <= 1) {
                return;
            }
            let n = 0;
            let i = 0;
            for (i = 0; i < focusableList.length; i++) {
                const item = focusableList[i];
                const $item = $(item);
                $item.removeClass("lfc");
                if ($item.is(currentTarget)) {
                    if (evt.keyCode == 37) {
                        n = Math.max(i - 1, 0);
                    } else if (evt.keyCode == 39) {
                        n = Math.min(i + 1, focusableList.length - 1);
                    }
                    break;
                }
            }
            const next = focusableList[n];
            $(next).addClass("lfc");
            next.focus();
        },
        horizontalControlKey(evt) {
            if (evt.keyCode != 37 // left
                && evt.keyCode != 39 // right
                && evt.keyCode != 38 // up
                && evt.keyCode != 40 // down
            ) {// only accept left, right, down, up
                return;
            }
            const currentTarget = evt.currentTarget;
            const horizontalControl = $(currentTarget).closest("ul");
            const focusableList = horizontalControl.find("> li > .focusable:visible, > div > li > .focusable:visible");
            if (focusableList.length <= 1) {
                return;
            }
            let n = 0;
            let i = 0;
            for (i = 0; i < focusableList.length; i++) {
                const item = focusableList[i];
                const $item = $(item);
                $item.removeClass("lfc");
                if ($item.is(currentTarget)) {
                    if (evt.keyCode == 38) {
                        n = Math.max(i - 1, 0);
                    } else if (evt.keyCode == 40) {
                        n = Math.min(i + 1, focusableList.length - 1);
                    }
                    break;
                }
            }
            const next = focusableList[n];
            const $next = $(next);
            if (evt.keyCode == 38 || evt.keyCode == 40) {
                $next.addClass("lfc");
                next.focus();
            }
            if (evt.keyCode == 39) {
                const $subUl = $next.parent().find("> ul");
                if ($subUl.length <= 0) {//không có sub >> back to parent
                    return;
                }
                $next.addClass("opensub");
                setTimeout(() => {
                    $subUl.find("> li > .focusable:visible,> div > li > .focusable")[0].focus();
                }, 200);
            } else if (evt.keyCode == 37) {
                $next.removeClass("opensub");
                const $subLi = $next.parents(".sub");
                if ($subLi.length > 0) {
                    setTimeout(() => {
                        const $focusable = $subLi.find("> .focusable:visible");
                        $focusable.focus();
                        $focusable.removeClass("opensub");
                    }, 0);
                }
            }
        }
    },
    watch: {
        listCalendarId(data) {
            if (data && data.length > 0) {
                this.searchListEventToWarning(data);
            }
        }
    }
};
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
}

.body-content {
    margin-right: 50px;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
}

.right-toolbar {
    width: 50px;
    height: 100%;
    position: fixed;
    right: 0px;
    border: 1px solid gray;
}

.mail-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.mail-wrap {
    display: flex;
    flex-wrap: wrap;
}
</style>
<style lang="scss">
@import "@/assets/scss/setting.scss";
@import "@/assets/scss/quick-setting.scss";
@import "@/assets/scss/account.scss";
@import "@/assets/scss/auto-rep.scss";
@import "@/assets/scss/calendar.scss";
@import "@/assets/scss/contact.scss";
@import "@/assets/scss/inbox.scss";
@import "@/assets/scss/signature.scss";
@import "@/assets/scss/truthful-address.scss";
@import "@/assets/scss/briefcase.scss";
@import "@/assets/scss/modules/inbox.scss";
@import "@/assets/scss/responsive.scss";
@import "@/assets/scss/setting/skin.scss";
@import "@/assets/scss/setting/font.scss";
@import "@/assets/scss/setting/tablet_mobile.scss";
@import "@/assets/scss/setting/tablet_moblie_setting.scss";
@import "@/assets/scss/contact/tablet_mobile_contact.scss";
@import "@/assets/scss/app.scss";
</style>
