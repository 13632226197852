<template>
    <div class="feedback-container" @click="handleFeedback">
        <img width="24px" style="cursor: pointer;"
             :src="require('@/assets/images/mailbox.svg')" />
        <strong>
            {{$t('zimbraNokey.feedback_mailbox')}}
        </strong>
    </div>
</template>

<script>
import {EMAIL_ACTION} from "@/utils/constants";

export default {
    name: "Feedback",
    data(){
        return{
            EMAIL_ACTION: EMAIL_ACTION,
            dataModel: {
                type: "",
                to:{
                    a:"hotro_vmail@viettel.com.vn",
                    email: 'Hỗ trợ vMail <hotro_vmail@viettel.com.vn>',
                    first:"Hỗ trợ vMail",
                    p:"Hỗ trợ vMail",
                    showExpand:true,
                    text: 'Hỗ trợ vMail <hotro_vmail@viettel.com.vn>',
                },
                subject: this.$t('zimbraNokey.feedback_mailbox_subject')

            },
        }
    },
    computed:{
        readRightMode(){
            return this.$route.path.includes('/email')
        }
    },
    methods:{
        handleFeedback(){
                this.$root.$emit('handleNavbarAction', this.EMAIL_ACTION.FEED_BACK, this.dataModel)
        }
    }
}
</script>


<style lang="scss" scoped>
.feedback-container {
    z-index: 1000;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    background: var(--color-primary);
    padding: 10px 6px;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    strong {
        color: #fff !important;
        white-space: nowrap;
        margin-left: 6px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover {
        width:  160px;
    }

    &:hover strong {
        opacity: 1;
    }
}
</style>

